<template>
  <div class="outer-wrapper">
    <div v-if="error" id="error">
      <div class="alert alert-error" v-if="error == 'card'">
        <h4>There was a problem taking payment for your order.<br/>Please double-check your details or try a different payment method.</h4>
        <p v-if="errorMessage">The bank said: <strong>{{errorMessage}}</strong></p>
      </div>
      <div class="alert alert-error" v-if="error == 'validation'">
        <p>There was a problem processing your order.<br/>Please double-check your details below and correct any missing details.</p>
      </div>

    </div>
    
    <div v-if="coreProducts.length == 0">
      <div class="no-products">
        <div class="no-products__text">
          <h2>Please add at least one Evermat&trade; Sedum Blanket System product to your cart.</h2>
          <p>Borders, Upstands and other optional extras can't be purchased without a Sedum Blanket System.</p>
          <a href="/products" class="button button--underline">Continue Shopping</a>
        </div>
      </div>
    </div>

    <div v-show="coreProducts.length > 0">
      <div class="checkout__header">
        <h1>Complete your Order</h1>
      </div>

      <div class="checkout__add-more">
        <a href="/products" class="button button--underline">Add More Items</a>
      </div>

      <div class="checkout__body">
        <div class="checkout__form">  
          
          <div class="m-basket">
            <div class="m-basket__core-products">
              <div class="m-basket__product" v-for="item in coreProducts" :key="item.id">
                <div class="m-basket__product__name">
                  <h4 class="name" v-html="item.product.name" />
                  <!-- <p class="description">{{ item.install_kit === "2-person" ? "2-Person Easy Install Kit" : "1-Person Easy Install Kit" }}</p> -->
                </div>
                
                <div class="m-basket__product__quantity">
                  <div class="calculator">
                    <div class="calculator__container">
                      <div class="calculator__quantity-and-unit">
                        <div class="calculator__quantity">
                          <span id="calculator_decrement" class="calculator__quantity__minus" v-on:click="decrement(item, 1)">-</span>
                          <div class="calculator__quantity__input">
                            <input id="calculator_quantity" type="number" min="0" pattern="\d+" 
                              onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" step="1" 
                              name="quantity"  
                              @change="amtChange"
                              v-model.number="item.quantity">
                          </div>
                          <span id="calculator_increment" class="calculator__quantity__plus" v-on:click="increment(item, 1)">+</span>
                        </div>
                        <span class="unit">m&sup2;</span>
                      </div>

                      <div class="m-basket__product__quantity__cost">
                        <span class="cost">{{ item.price * item.quantity | currency(order.currency_unit) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="m-basket__optional-extras">
              <h2 class="m-basket__heading">Optional extras:</h2>

              <div class="m-basket__product" v-for="item in extraProducts" :key="item.id">
                <div class="m-basket__product__name">
                  <div class="m-basket__product__image">
                    <img :src="item.product.hero_url" />
                  </div>
                  <div class="m-basket__product__info">
                    <h4 class="name" v-html="item.product.name" />
                    <div class="price">
                      <p>{{item.product.price | currency(order.currency_unit) }} +VAT</p>
                      <span class="view-details" @click="prepModal(item.product_id)">View Details</span>
                    </div>
                  </div>
                </div>
                <div class="m-basket__product__quantity">
                  <div class="calculator__container">
                    <div class="calculator__quantity-and-unit">
                      <div class="calculator__quantity">
                        <span id="calculator_decrement" class="calculator__quantity__minus" v-on:click="decrement(item, 1)">-</span>
                        <div class="calculator__quantity__input">
                          <input id="calculator_quantity" type="number" min="0" pattern="\d+" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" step="1" name="quantity"  
                          
                              @change="amtChange"
                          v-model.number="item.quantity" ><span></span>
                        </div>
                        <span id="calculator_increment" class="calculator__quantity__plus" v-on:click="increment(item, 1)">+</span>
                      </div>
                      <span class="unit">{{item.product.unit}}</span>
                    </div>

                    <div class="m-basket__product__quantity__cost" v-if="item.quantity > 0">
                      <span class="cost">{{ (item.product.price * item.quantity) | currency(order.currency_unit) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="m-basket__subtotal">
              <div class="label">
                <p>Sub-total:</p>
              </div>
              <div class="subtotal">
                <p class="cost">{{order.subtotal | currency(order.currency_unit) }}</p> 
                <p class="vat"><small>+VAT</small></p>
              </div>
            </div>
          </div>

          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <form class="form form--checkout" id="checkout-form" @submit.prevent="handleSubmit(submit)">

              <!-- DELIVERY DETAILS -->
              <h2 class="m-basket__heading">Delivery Details:</h2>
              <div class="form__row form__row--spaced">
                <div class="form__field">
                  <label for="first_name">First Name</label>
                  <ValidationProvider v-slot="{ errors }" vid="first_name" rules="required">
                    <input type="text" id="first_name" name="first_name" v-model="order.first_name" placeholder="First Name" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form__field">
                  <label for="last_name">Last Name</label>
                  <ValidationProvider v-slot="{ errors }" vid="last_name" rules="required">
                    <input type="text" id="last_name" name="last_name" v-model="order.last_name" placeholder="Last Name" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="form__row form__row--stacked">
                <div class="form__field">
                  <label for="address">Address</label>
                  <ValidationProvider v-slot="{ errors }" vid="shipping_line1" rules="required">
                    <input type="text" id="shipping_line1" name="shipping_line1" v-model="order.shipping_line1" placeholder="Address - Line 1" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form__field">
                  <input type="text" id="shipping_line2" name="shipping_line2" v-model="order.shipping_line2" placeholder="Address - Line 2" />
                </div>
              </div>

              <div class="form__row form__row--spaced">
                <div class="form__field">
                  <label for="town">Town/City</label>

                  <ValidationProvider v-slot="{ errors }" vid="shipping_town" rules="required">
                    <input type="text" id="shipping_town" name="shipping_town" v-model="order.shipping_town" placeholder="Town/City" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="form__field">
                  <label for="shipping_postcode">Postcode</label>

                  <ValidationProvider v-slot="{ errors }" vid="shipping_postcode" rules="required">
                    <input type="text" id="shipping_postcode" name="shipping_postcode" v-model="order.shipping_postcode" placeholder="Postcode" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="form__row form__row--spaced">
                <div class="form__field">
                  <label for="shipping_county">County</label>
                  <input type="text" id="shipping_county" name="shipping_county" v-model="order.shipping_county" placeholder="County" />
                </div>
                <div class="form__field">
                  <label for="shipping_country">Country</label>
                  <select name="shipping_country_id" v-model="order.shipping_country_id" @change="updateCountry">
                    <option value="1">United Kingdom</option>
                    <option value="2">Ireland</option>
                    <option value="34">France</option>
                  </select>
                </div>
              </div>

              <div class="form__row">
                <div class="form__field">
                  <label for="phone">Phone Number <span>(we'll need this to coordinate your delivery time)</span></label>

                  <ValidationProvider v-slot="{ errors }" vid="telephone" rules="min:10">
                    <input type="tel" id="telephone" name="telephone" v-model="order.telephone" placeholder="Phone Number" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="form__row">
                <div class="form__field">
                  <label for="email">Email Address <span>(we'll need this to confirm your order)</span></label>

                  <ValidationProvider v-slot="{ errors }" vid="email" rules="required|email">
                    <input type="email" id="email" name="email" v-model="order.email" placeholder="Email Address" />
                    <span class="error">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>

              <div class="form__row">
                <div class="form__field">
                  <label for="delivery_date_at">Choose your Delivery Date</label>
                  <ValidationProvider v-slot="{ errors }" vid="delivery_date_at" rules="required">
                    <datepicker v-model="order.delivery_date_at" format="D, dd MMMM yyyy" :disabled-dates="disabledDates" placeholder="Delivery Date" @selected="updateDeliveryDate" />
                    <span class="error">{{ errors[0] }}</span>
                    <span class="note" v-if="isSaturday">
                      <svg width="15px" height="15px" version="1.1" viewBox="0 0 1200 1200" xmlns="http://www.w3.org/2000/svg">
                        <path d="m605.2 10.703c-325.43 0-589.22 263.8-589.22 589.22 0 325.43 263.8 589.23 589.22 589.23 325.43 0 589.21-263.81 589.21-589.23s-263.78-589.22-589.21-589.22zm102.8 937.67c0 52.824-45.672 95.629-102 95.629s-102-42.805-102-95.629v-438.74c0-52.812 45.672-95.629 102-95.629s102 42.816 102 95.629zm-102.8-573.8c-60.574 0-109.69-49.105-109.69-109.7 0-60.59 49.105-109.69 109.69-109.69 60.602 0 109.7 49.105 109.7 109.69-0.011719 60.602-49.113 109.7-109.7 109.7z"/>
                      </svg>
                      <em>Please note there is a {{ surchargeAmount | currency(order.currency_unit, '.', 0) }} surcharge for Saturday delivery</em></span>
                  </ValidationProvider>
                </div>
              </div>

              <!-- BILLING DETAILS -->
              <div class="m-basket__billing-details">
                <h2 class="m-basket__heading">Billing Address:</h2>
                <p>Is your billing address the same as your shipping address?</p>
                <div class="radio-buttons">
                  <label class="radio-input" :class="{active: order.billing_same_as_shipping}">
                    <input type="radio" v-model="order.billing_same_as_shipping" :value="true">
                    <span>Yes</span>
                  </label>
                  <label class="radio-input" :class="{active: !order.billing_same_as_shipping}">
                    <input type="radio" v-model="order.billing_same_as_shipping" :value="false">
                    <span>No, I want to specify a different billing address</span>
                  </label>
                </div>
                <div v-if="!order.billing_same_as_shipping" class="m-basket__billing-details__form">
                  <div class="form__row form__row--stacked">
                    <div class="form__field">
                      <label for="address">Address</label>
                      <ValidationProvider v-slot="{ errors }" vid="billing_line1" rules="required">
                        <input type="text" id="billing_line1" name="billing_line1" v-model="order.billing_line1" placeholder="Address - Line 1" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form__field">
                      <input type="text" id="billing_line2" name="billing_line2" v-model="order.billing_line2" placeholder="Address - Line 2" />
                    </div>
                  </div>

                  <div class="form__row form__row--spaced">
                    <div class="form__field">
                      <label for="town">Town/City</label>

                      <ValidationProvider v-slot="{ errors }" vid="billing_town" rules="required">
                        <input type="text" id="billing_town" name="billing_town" v-model="order.billing_town" placeholder="Town/City" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div class="form__field">
                      <label for="billing_postcode">Postcode</label>

                      <ValidationProvider v-slot="{ errors }" vid="billing_postcode" rules="required">
                        <input type="text" id="billing_postcode" name="billing_postcode" v-model="order.billing_postcode" placeholder="Postcode" />
                        <span class="error">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="form__row form__row--spaced">
                    <div class="form__field">
                      <label for="billing_county">County</label>
                      <input type="text" id="billing_county" name="billing_county" v-model="order.billing_county" placeholder="County" />
                    </div>
                    <div class="form__field">
                      <label for="billing_country">Country</label>
                      <select name="billing_country_id" v-model="order.billing_country_id">
                        <option value="1">United Kingdom</option>
                        <option value="2">Ireland</option>
                        <option value="34">France</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>


              <!-- PAYMENT DETAILS -->
              <div class="m-basket__payment-details">
                <h2 class="m-basket__heading">Payment Details:</h2>
                <div class="radio-buttons">
                  <label class="radio-stripe" :class="{active: payment == 'stripe'}">
                    <input type="radio" v-model="payment" value="stripe">
                    <span>Credit Card</span>
                  </label>
                  <label class="radio-paypal" :class="{active: payment == 'paypal'}">
                    <input type="radio" v-model="payment" value="paypal">
                    <span>Paypal</span>
                  </label>
                </div>
              </div>
              
              <div class="m-basket__total-cost">
                <h2 class="m-basket__heading">Total Cost:</h2>
                <div class="m-basket__total-cost__upper">
                  <div class="m-basket__total-cost__left">
                    <span class="free-delivery" v-if="isSaturday">
                      Reduced Saturday delivery on orders of 20m<sup>2</sup> or more
                    </span>
                    <span class="free-delivery" v-else>
                      Free weekday delivery
                      on orders of 20m<sup>2</sup> or more
                    </span>
                      
                  </div>

                  <div class="m-basket__total-cost__right">
                    <div class="cost">
                      <span class="label">Sub-total:</span><span class="amount">{{ order.subtotal | currency(order.currency_unit) }}</span>
                    </div>
                    <div class="cost">
                      <span class="label">Delivery:</span>
                      <span class="amount">
                        <span class="amount-strike" v-if="order.original_shipping > 0 && order.original_shipping != order.shipping">{{order.original_shipping | currency(order.currency_unit) }}</span>
                        <template v-if="order.shipping > 0">{{order.shipping | currency(order.currency_unit) }}</template><template v-else>Free</template>
                      </span>
                    </div>


                    <div class="cost" v-if="order.discount_amount > 0">
                      <span class="label">Discount ({{order.discount.percentage}}%):</span><span class="amount"> - {{order.discount_amount | currency(order.currency_unit) }}</span>
                    </div> 


                    <div class="cost">
                      <span class="label">VAT:</span><span class="amount">{{order.tax | currency(order.currency_unit) }}</span>
                    </div>                   
                  </div>
                </div>
                <div class="m-basket__total-cost__lower">
                  <div class="m-basket__total-cost__right">
                    <div class="cost">
                      <span class="label">Total:</span><span class="amount">{{order.total | currency(order.currency_unit) }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="m-basket__confirmation">
                <div class="terms">
                  <ValidationProvider vid="terms" v-slot="{ errors }" :rules="{ required: { allowFalse: false } }">
                    <label>
                      <input type="checkbox" v-model="order.terms" :value="true" />
                      <span class="label">I agree to the <a href="/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a></span>
                    </label>
                    <p class="error">{{ errors[0] }}</p>
                  </ValidationProvider>
                </div>

                <template v-if="isSubmitting">
                  Processing
                </template>
                <template v-else>


                  <button v-show="payment == 'stripe'" type="submit" class="button button--dark-green" :disabled="isSubmitting">Continue to payment</button>
                  <div v-show="payment == 'paypal'">
                    <div id="paypal-button-container" ref="paypal"></div>
                  </div>
                </template>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="checkout__image">
          <img :src="coreProductImage" v-if="coreProductImage" />
        </div>
      </div>

      <modal v-if="showModal" @close="showModal = false" :extra="modalExtra" :hasCalc="false"></modal>
    </div>
                    
  </div>
</template>

<script>
import Api from "../utils/api";
import EventBus from '../utils/event-bus';
import Modal from './modal.vue';
import _ from 'lodash';
import Datepicker from 'vuejs-datepicker';
import dayjs from 'dayjs';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { TransactionService } from 'opayo-form-integration-js';

extend('email', {
  ...email,
  message: 'Please enter a valid email address'
});
extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  props: ["errorcode","errormsg", "extras", "sageurl", "blackout", "surcharge"],
  
  data() {
    return {
      order: {},
      checkout: null,
      isSubmitting: false,
      payment: "stripe",
      error: this.errorcode,
      errorMessage: this.errormsg,
      showModal: false,
      modalExtra: {},
    }
  },
  components: {
    Modal,
    ValidationProvider,
    ValidationObserver,
    Datepicker
  },
  mounted() {
    this.loadOrder()
    this.$nextTick(function() {
      paypal.Buttons({
        style: {
          shape: "pill",
          label: "pay"
        },
        createOrder: this.paypalCreateOrder,
        onClick: this.paypalOnClick,
        onApprove: this.paypalApprove
      }).render('#paypal-button-container');
    })
  },
  computed: {

    surchargeAmount() {
      if(this.order.currency == "EUR") {
        return this.surcharge.eur
      } else {
        return this.surcharge.gbp
      }
    },

    isSaturday() {
      return dayjs(this.order.delivery_date_at).day() === 6
    },
  
    disabledDates() {

      const blackoutDates = this.blackout

      return {
        days: [0,1,2,6],
        customPredictor: function(date) {

          let d = dayjs(date)
          // disables the date if it is a multiple of 5
          if(d.isBefore(dayjs().add(7,'d'))){
            return true
          }
          if(d.isAfter(dayjs().add(8,'week'))) {
            return true

          }

          if(_.some(blackoutDates, o => dayjs(o).isSame(d,'day'))) {
            return true
          }
        }
      }
    },


    coreProducts() {
      return _.filter(this.order.line_items, o => o.product.product_type === 'core')
    },
    extraProducts() {
      if(!this.order) {
        return [] 
      }
      return _.filter(this.order.line_items, o => o.product.product_type === 'extra')
    },
    coreProductImage() {
      const firstItem = _.first(this.coreProducts)
      if(firstItem) {
        return firstItem.product.hero_url
      } else {
        return null
      }
    },
    coreProductNames() {
      return _.map(this.coreProducts, o => o.product.name)
    }
  },
  watch: {
    order: {
      handler(old, newData) {
        EventBus.$emit('update_quantity', this.order.quantity);
      },
      deep: true
    }
  },
  methods: {
    updateDeliveryDate(event) {
      return Api.post('/basket/delivery', {
        delivery_date_at: event
      }).then(response => {
        this.order = {
          ...this.order,
          line_items: response.data.line_items,
          shipping_country_id: response.data.shipping_country_id,
          currency: response.data.currency,
          currency_unit: response.data.currency_unit,
          subtotal: response.data.subtotal,
          discount_amount: response.data.discount_amount,
          tax: response.data.tax,
          total: response.data.total,
          shipping: response.data.shipping,
          original_shipping: response.data.original_shipping
        }
      })
    },
    updateCountry(event) {
      return Api.post('/basket/country', {
        country_id: this.order.shipping_country_id
      }).then(response => {
        this.order = {
          ...this.order,
          line_items: response.data.line_items,
          shipping_country_id: response.data.shipping_country_id,
          currency: response.data.currency,
          currency_unit: response.data.currency_unit,
          subtotal: response.data.subtotal,
          discount_amount: response.data.discount_amount,
          tax: response.data.tax,
          total: response.data.total,
          shipping: response.data.shipping,
          original_shipping: response.data.original_shipping
        }
      })
    },
    paypalApprove(data, actions) {
      return actions.order.capture().then((details) => {
        this.isSubmitting = true
        return Api.post('/paypal', {
          paypal: data.orderID,
          order: this.order,
          details
        }).then(response => {
          this.isSubmitting = false
          if(response.data.status === "Ok") {
            window.location = response.data.url
          } else {
            this.error = "card"
            this.errorMessage = response.data.statusDetail
            window.scrollTo(0,0)
          }
        }).catch((e,a) => {
          this.isSubmitting = false
          if(e.response.status == 422) {
            let errors = {}
            _.forEach(e.response.data.errors, (v,k) => {
              errors[k] = v
            })
            this.$refs.form.setErrors(errors)
            this.error = "validation"
          } else {
            this.error = "card"
          }
          window.scrollTo(0,0)
        })
      });
    },
    paypalOnClick(data, actions) {
      return this.$refs.form.validate().then(success => {
        if(!success) {
          this.error = "validation"
          window.scrollTo(0,0)
          return actions.reject()
        } else {
          return actions.resolve()
        }
      }).catch(() => {
        this.error = "validation"
        window.scrollTo(0,0)
        return actions.reject()
      })
    },
    paypalCreateOrder(data, actions) {
      return actions.order.create({
        application_context: {
          shipping_preference: 'NO_SHIPPING'
        },
        purchase_units: [{
          reference_id: this.order.reference,
          amount: { 
            value: parseFloat(this.order.total).toFixed(2),
            currency: this.order.currency
          }
        }]
      });
    },
    prepModal: function(extraId) {
      this.modalExtra = this.extras.filter(extra => extra.id === extraId)[0];
      this.showModal = true;
    },
    blockSubmit() {
      this.error = null
      this.errorMessage = null
      // this.checkout.tokenise()
      return false
    },
    amtChange() {
      this.updateOrder()
    },
    increment: function(item, amt) {
      item.quantity += amt;
      this.updateOrder()
    },
    decrement: function(item, amt) {
      item.quantity -= amt;
      if (item.quantity < 0) {
        item.quantity = 0;
      }
      this.updateOrder()
    },
    updateOrder: _.debounce(function() {
        Api.post('/basket/update', {
          order: this.order
        }).then(response => {
          this.order = response.data
        })
    }, 500),

    submit() {

      this.$refs.form.validate().then(success => {
        if (!success) {
          this.error = "validation"
          window.scrollTo(0,0)
          return;
        }
        this.isSubmitting = true
        Api.post('/checkout', {
          order: this.order
        }).then(response => {
          const transaction = response.data

          if(transaction.url) {
            window.location = transaction.url
          }
          


          this.isSubmitting = false
        }).catch((e,a) => {
          this.isSubmitting = false
          if(e.response.status == 422) {
            let errors = {}
            _.forEach(e.response.data.errors, (v,k) => {
              errors[k] = v
            })
            this.$refs.form.setErrors(errors)
            this.error = "validation"
          } else {
            this.error = "card"
          }
          window.scrollTo(0,0)
          this.getSessionKey()
        })
      })
    },
    loadOrder() {
      Api.get("/basket")
        .then(response => {
          this.order = response.data
        })
    }
  }
}
</script>

<style lang="scss">
  #sp-container {
    margin-bottom: 18px;
    padding: 20px;
    background-color: #FFF;
    min-height: 200px !important;
  }

  .no-products {
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__text {
      max-width: 660px;
      text-align: center;
      
      h2 {
        color: #5E285F;
        font-size: 1.875em;
        margin-bottom: 28px;
      }

      p {
        font-size: 1.125em;
        line-height: 1.4;
        margin-bottom: 28px;
      }
    }
  }

  .alert {
    padding: 28px 0;
    max-width: 460px;
    margin: 0 auto 28px;
    text-align: center;
    color: #E9260F;
    
    h4 {
      font-size: 1.25em;
      line-height: 1.4;
    }
  }
</style>